<template>
    <v-main class="grey lighten-4">
        <page-toolbar ref="toolbar" :title="$t('console.storage.toolbar.title')">
        </page-toolbar>

        <v-card id="card">
            <file-explorer
                :client="client"
                source="static"
            >
                <template #preview="{ activeItem, deleteFile, renameFile }">
                    <img
                        id="preview-img"
                        v-if="['jpg','jpeg','png','gif','bmp','ico'].includes(getFileExtension(activeItem.path))"
                        :src="`/static/${activeItem.path}`"
                    />
                    <audio
                        controls
                        id="preview-audio"
                        v-if="['wav','mp3','m4a'].includes(getFileExtension(activeItem.path))"
                        :src="`/static/${activeItem.path}`"
                    />
                    <video
                        controls
                        id="preview-video"
                        v-if="['mp4','webm'].includes(getFileExtension(activeItem.path))"
                        :src="`/static/${activeItem.path}`"
                    />
                    <v-divider></v-divider>
                    <v-simple-table dense>
                        <tbody>
                            <tr><th>{{ $t('console.storage.preview.headers.filePath') }}</th><td>/{{ activeItem.path }}</td></tr>
                            <tr>
                                <th>{{ $t('console.storage.preview.headers.url') }}</th>
                                <td>
                                    <a
                                        :href="getUrlForPath(activeItem.path)"
                                        target="_blank"
                                    >
                                        {{ getUrlForPath(activeItem.path) }}
                                    </a>
                                    <v-icon small right>mdi-open-in-new</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <div class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="downloadFile(activeItem)">mdi-download</v-icon>
                            </template>
                            <span>{{ $t('console.storage.preview.actions.download') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="deleteFile(activeItem)">mdi-delete</v-icon>
                            </template>
                            <span>{{ $t('console.storage.preview.actions.delete') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ attrs, on }">
                                <v-icon v-bind="attrs" v-on="on" class="ma-4" @click="renameFile(activeItem, activeItem.name)">mdi-pencil</v-icon>
                            </template>
                            <span>{{ $t('console.storage.preview.actions.rename') }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <template #label-append="{ active, item, renameFile, deleteFile }">
                    <v-icon v-if="active" small class="mx-1" @click.stop="renameFile(item, item.name)">mdi-pencil</v-icon>
                    <v-icon v-if="active" small class="mx-1" @click.stop="deleteFile(item)">mdi-delete</v-icon>
                </template>
            </file-explorer>
        </v-card>
    </v-main>
</template>
<script>
import PageToolbar from '@/components/ui/PageToolbar'
import FileExplorer from '@/components/ui/FileExplorer'
import { getFileExtension } from '@/lib/utils'

export default {
    components: { PageToolbar, FileExplorer },
    props: ['client'],
    methods: {
        getFileExtension,
        downloadFile(item) {
            const link = document.createElement('a');
            link.download = item.name;
            link.href = this.getUrlForPath(item.path);
            link.click()
        },
        getUrlForPath(path) {
            return `${window.location.origin}/static/${path}`;
        },
    }
}
</script>
<style scoped>
#card {
    margin: 40px;
    height: 600px;
}
</style>