var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c("page-toolbar", {
        ref: "toolbar",
        attrs: { title: _vm.$t("console.storage.toolbar.title") },
      }),
      _c(
        "v-card",
        { attrs: { id: "card" } },
        [
          _c("file-explorer", {
            attrs: { client: _vm.client, source: "static" },
            scopedSlots: _vm._u([
              {
                key: "preview",
                fn: function ({ activeItem, deleteFile, renameFile }) {
                  return [
                    ["jpg", "jpeg", "png", "gif", "bmp", "ico"].includes(
                      _vm.getFileExtension(activeItem.path)
                    )
                      ? _c("img", {
                          attrs: {
                            id: "preview-img",
                            src: `/static/${activeItem.path}`,
                          },
                        })
                      : _vm._e(),
                    ["wav", "mp3", "m4a"].includes(
                      _vm.getFileExtension(activeItem.path)
                    )
                      ? _c("audio", {
                          attrs: {
                            controls: "",
                            id: "preview-audio",
                            src: `/static/${activeItem.path}`,
                          },
                        })
                      : _vm._e(),
                    ["mp4", "webm"].includes(
                      _vm.getFileExtension(activeItem.path)
                    )
                      ? _c("video", {
                          attrs: {
                            controls: "",
                            id: "preview-video",
                            src: `/static/${activeItem.path}`,
                          },
                        })
                      : _vm._e(),
                    _c("v-divider"),
                    _c("v-simple-table", { attrs: { dense: "" } }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "console.storage.preview.headers.filePath"
                                )
                              )
                            ),
                          ]),
                          _c("td", [_vm._v("/" + _vm._s(activeItem.path))]),
                        ]),
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("console.storage.preview.headers.url")
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getUrlForPath(activeItem.path),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getUrlForPath(activeItem.path)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-icon",
                                { attrs: { small: "", right: "" } },
                                [_vm._v("mdi-open-in-new")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadFile(
                                                    activeItem
                                                  )
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-download")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.storage.preview.actions.download"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return deleteFile(activeItem)
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.storage.preview.actions.delete"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return renameFile(
                                                    activeItem,
                                                    activeItem.name
                                                  )
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "console.storage.preview.actions.rename"
                                  )
                                )
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "label-append",
                fn: function ({ active, item, renameFile, deleteFile }) {
                  return [
                    active
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "mx-1",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return renameFile(item, item.name)
                              },
                            },
                          },
                          [_vm._v("mdi-pencil")]
                        )
                      : _vm._e(),
                    active
                      ? _c(
                          "v-icon",
                          {
                            staticClass: "mx-1",
                            attrs: { small: "" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return deleteFile(item)
                              },
                            },
                          },
                          [_vm._v("mdi-delete")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }